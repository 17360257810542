import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthHelper } from '../helpers/AuthHelper';
import 'typeface-titillium-web';
import {SPIDReactButton} from '@dej611/spid-react-button'

import '@dej611/spid-react-button/dist/index.css'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://m-a-s-e.it/">
        m-a-s-e.it
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.


export default function ForgotPassword() {

  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState("")

  const [isSent, setIsSent] = React.useState(false);
  const [code, setCode] = React.useState("");

  const [user, setUser] = React.useState("");

  const [password, setPassword] = React.useState("");

  const forgot = async() => {
    setLoading(true);
    try{
      const user = await AuthHelper.forgotPassword(email);
 
      setUser(user);

      setIsSent(true);
      setLoading(false);

    //   window.setTimeout(() => {window.location.href="/";}, 1000)
      
    }catch(ex){
        alert("Errore server");
        console.log(ex);
    }
  }

  const confirmPassword = async() => {
        setLoading(true);

        const confirm = await AuthHelper.confirmPassword(code, password, user);
        console.log(confirm);

        setLoading(false);
        window.location.href="/login";

  }
  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <img src="/images/New Logo MASE  TR2 mod.png" width={125} height={125} alt="logo"/>

          <Typography component="h1" variant="h5">
            Recupera la tua password
          </Typography>
          {isSent &&  <Box noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Codice"
              name="code"
              onChange={(e) => setCode(e.target.value)}              
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}              
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
              onClick={confirmPassword}
            >
              Conferma codice
            </LoadingButton>
          </Box>}
          {!isSent &&  <Box noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}              
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
              onClick={forgot}
            >
              Recupera password
            </LoadingButton>
          </Box>}
         
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}