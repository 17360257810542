import React, { useEffect } from 'react';
import Card from '../components/Card';
import { Add, DashboardCustomize, ExpandMore, FilterList, Language, Newspaper } from '@mui/icons-material';
import CustomButton from '../components/Button';

import { Box, Typography } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import CardCertificati from '../components/CardCertificati';
import CardImbarchiExpanded from '../components/CardImbarchiExpanded';
import { AuthHelper } from '../helpers/AuthHelper';
import moment from 'moment';

const style={
    container: {
        paddingLeft: 12, 
        paddingRight: 12,
    }
}
const TrovaImbarco = () => {
    const [items, setItems] = React.useState([
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'},
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'},
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'}
    ])
    const [onboardings, setOnboardings] = React.useState([]);

    const getOnboardings = async() => {
        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
        const role = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/role/${_user.filter(x => x.Name === 'sub')[0]?.Value}`);
        
        const id = (await role.json()).roleId
        const _onboardings = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/proposals/${id}`);
        setOnboardings([...await _onboardings.json()]);
        
    }
    useEffect(() => {
        getOnboardings();
    }, [])

    const getRole = (id) => {
        const roles = JSON.parse(sessionStorage.getItem('roles'));
        return roles.filter(x => x.id === id)[0]?.name
    }
    return (<div>
            <span style={{fontSize: '2rem', fontWeight: 600, color: '#3d4a71', marginBottom: 12, display: 'block'}}>Trova un imbarco</span>
        {/* <Box elevation={3} sx={{width: '100%', height: 85, marginTop: '23px',  backgroundColor: '#fff', boxShadow: '0px -1px 6px #00000029', display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', alignItems: 'center', paddingLeft: '28px'}}>
                <Typography color="primary" textTransform={'uppercase'} fontSize="16px" alignItems="center" display="flex">Filtra &nbsp;<FilterList fontSize="16px"/></Typography>

            </div>
            <div style={{display: 'flex', alignItems: 'center', paddingRight: '28px'}}>
                <Typography color="primary" textTransform={'uppercase'} fontSize="16px" alignItems="center" display="flex">Ordina per &nbsp;<ExpandMore fontSize="16px"/></Typography>

            </div>
        </Box> */}
        <div style={style.container}>
            
            
            <div style={{display: 'flex', width: '100%', flexDirection: 'column', marginTop: '2rem'}}>{
                onboardings.map((item, idx) => (<>
                        <CardImbarchiExpanded name={item.shipper} title={`Proposta ${idx + 1}`} shipmentStart={moment(item.startDate).format('DD/MM/YYYY')} shipmentEnd={moment(item.endDate).format('DD/MM/YYYY')} location={item.location} role={getRole(item.role)} />
                    </>
                ))
            }</div>
        </div>
        {/* <CustomButton text="Carica altri" fontFamily="Spectral" inverse={true} style={{marginTop: 24}}/> */}
        
    </div>
    
    
    )
    
}

export default TrovaImbarco;