import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    viewBox="0 0 1920 217"
    {...props}
  >
    <g fillRule="evenodd">
      <path
        d="M1920 57.469c-203.365 77.749-494.836 99.095-874.414 64.039C727.388-13.982 378.859-35.33 0 57.468V207h1920V57.469Z"
        opacity={0.3}
      />
      <path
        d="M1920 79c-292.469 86.453-612.469 86.453-960 0C612.469-7.453 292.469-7.453 0 79v128h1920V79Z"
        opacity={0.6}
      />
      <path d="M1920 89c-288.714 57.786-608.714 57.786-960 0C608.714 31.214 288.714 31.214 0 89v128h1920V89Z" />
    </g>
  </svg>
)
export default SvgComponent