import React from "react";
import CustomButton from "./Button";

const FadedBackgroundDiv = (props) => (<div className="fadedBackground" style={{width: '100%', backgroundImage: props.source ? `url(${props.source})` : 'url(/images/imbarchi.jpeg)', backgroundPosition: 'center', backgroundSize: 'cover'}}>
    <div className="content">
        <h2 style={{paddingTop: 12, fontSize: 32}}>{props.text}</h2>

        {props.content}
        
    </div>
    <CustomButton className="absolute-btn" text="Vedi tutti" onClick={props.action} />

</div>);
export default FadedBackgroundDiv

