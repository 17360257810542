import * as React from 'react';

import Container from '@mui/material/Container';

import 'typeface-titillium-web';

import '@dej611/spid-react-button/dist/index.css'

import { useParams } from 'react-router-dom'
import ImageGallery from "react-image-gallery";
import moment from 'moment';
import { Close } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { AuthHelper } from '../helpers/AuthHelper';

function isDateGreaterThanToday(date) {
    // Create a moment object for the given date
    const givenDate = moment(date);

    // Create a moment object for today
    const today = moment().startOf('day'); // Start of the day to ignore time

    // Compare the dates
    return givenDate.isAfter(today);
}

export default function Notifications() {

  const [loading, setLoading] = React.useState(false)

  const [request, setRequest] = React.useState({
    type: "",
    service: "",
    name: "",
    email: "",
    phone: "",
    message: ""
  })
  
  const [school, setSchool] = React.useState({});

  const [courses, setCourses] = React.useState([]);

  const [Notifications, setNotifications] = React.useState([]);

  const getTimePassed = (createdAt) => {
        const pastTimestamp = moment(createdAt);

        // Timestamp corrente
        const currentTimestamp = moment();

        // Differenza in millisecondi
        const differenceInMillis = currentTimestamp.diff(pastTimestamp);

        // Conversioni
        const seconds = moment.duration(differenceInMillis).asSeconds();
        const minutes = moment.duration(differenceInMillis).asMinutes();
        const hours = moment.duration(differenceInMillis).asHours();
        const days = moment.duration(differenceInMillis).asDays();
        const months = moment.duration(differenceInMillis).asMonths();

        if(seconds > 60){
          if(days > 30)return `${Math.floor(months)} mesi fa`;
          if(hours > 24)return `${Math.floor(days)} giorni fa`;
          if(minutes > 60)return `${Math.floor(hours)} ore fa`;
          return `${Math.floor(minutes)} minuti fa`;
        }else{
          return `${Math.floor(seconds)} secondi fa`;
        }
  }
  const getNotifications = async() => {
      const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/notifications/${_user.filter(x => x.Name === 'sub')[0]?.Value}`)
      let notifications = await res.json();
      console.log(notifications.map(x => getTimePassed(x.createdAt)));
      setNotifications([...notifications]);
  }
  const sendRequest = async() => {
    setLoading(true);
    try{
      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/services`, {
        method: 'POST',
        body: JSON.stringify(request)
      });
      setLoading(false);
      alert("Richiesta inviata con successo!");
    }catch(ex){
      alert('Errore server');
      console.log(ex);
      setLoading(false);
    }
    

  }

  const { id } = useParams();

  const getSchool = async() => {
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/school/${id}`);
    setSchool(await res.json());
  }

  const getCourses = async() => {
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/courses/${id}`);
    setCourses([...await res.json()])
  }
  React.useEffect(() => {
    getSchool();
    getCourses();
    getNotifications();
  }, []);
  return (
    <>
    {<div component="main" maxWidth="xs" style={{paddingLeft: 24, paddingRight: 24}}>
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
          <span style={{fontSize: '2rem', fontWeight: 600, color: 'rgba(6, 32, 105, 1)', marginTop: 24, marginBottom: 12, display: 'block'}}>Notifiche</span>
          {Notifications.map(x => (
            <Card>
            <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: 15, paddingBottom: 15, paddingLeft: 24, paddingRight: 24}}>
              <strong style={{color: '#000'}}>
                  {x.type}
                  <span style={{color: '#777', paddingLeft: 12}}>{getTimePassed(x.createdAt)}</span>
              </strong>
              <Close onClick={async () => {
                await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/notifications/${x.id}`, {
                  method: 'DELETE'
                });
                await getNotifications();
              }} />
            </div>
            <CardContent sx={{paddingTop: 0}}>
            <p style={{color: '#777', textAlign: 'left', marginTop: 0}}>
            {x.title}</p>
            </CardContent>
            {/* <CardActions>
              <Button size="small">Azione 1</Button>
              <Button size="small">Azione 2</Button>
            </CardActions> */}
          </Card>
          ))}
          
          
        </div>
    </div>}
    </>
      
  );
}