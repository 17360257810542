import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import Modal from '@mui/material/Modal';
import CustomButton from './Button';

const PdfViewer = ({pdf, onCancel, visible, close})=> {

    const [page, setPage] = useState(1);
    const canvasRef = useRef(null);
    const { pdfDocument, pdfPage } = usePdf({
      file: pdf,
      page,
      canvasRef,
      scale: 0.153
    });
    const modalStyle = {
        position: 'absolute',
        right: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        top: 0,
        pt: 3,
        display: 'flex',
        alignItems: 'center'
      };
    return (
        <Modal sx={modalStyle} open={visible}>
<div>
        {!pdfDocument && <span>Caricamento...</span>}
        <CustomButton small text={"Chiudi"} style={{marginBottom: 24, float: 'right'}} onClick={() => close()}></CustomButton>

        <canvas ref={canvasRef} />
        {Boolean(pdfDocument && pdfDocument.numPages) && (
          <nav style={{display: 'flex', justifyContent: 'space-between'}}>
                <CustomButton small text={"Precedente"} disabled={page === 1} onClick={() => setPage(page - 1)}></CustomButton>
                <CustomButton
                  text={"Successivo"}
                  small
                  disabled={page === pdfDocument.numPages}
                  onClick={() => setPage(page + 1)}
                >
                </CustomButton>
          </nav>
        )}
      </div>
        </Modal>
      
    );
  
};
export default PdfViewer;