import { EmailOutlined, LanguageOutlined, PhoneOutlined, PinDropOutlined } from '@mui/icons-material';
import React from 'react';

const style = {
    card: {
        width: 275,
        backgroundColor: '#fff',
        color: '#1B2B58',
        boxShadow: '0px 0px 12px 0px rgba(0,0,0,0.25)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        padding: 21,
        flexShrink: 0,
        marginBottom: 24,
        marginTop: 18
    },
    widget: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#555',
        width: '100%'
    },
    singleWidget: {
        flexDirection: 'column',
        display: 'flex'
    }
}
const Card = (props) => {
    return (
    <div style={style.card}>
        <div>{props.icon}</div>
        <div><h2 style={{fontSize: '24px', minHeight: 54}}>{props.text}</h2></div>
        <div style={style.widget}>
            {props.widgets.map((widget, index) => (
                <>
                {index > 0 && <div style={{height: '100%', width: 2, backgroundColor: '#eee'}}/>}

                <div style={style.singleWidget}>

                    <span style={{fontWeight: 'regular', textTransform: 'uppercase', fontFamily: "'Roboto', sans-serif", fontSize: '0.8rem'}}>
                        {widget.title}
                    </span>
                    <span style={{fontWeight: 'bold', fontSize: '1.3rem'}}>
                        {widget.description}
                    </span>
                </div>

                </>
                
                
            ))}
        </div>
        
        <div style={{display: 'flex', flexDirection: 'column', marginTop: '2rem'}}>
            <div style={{display: 'flex', color:'#1B2B58'}}>
                <PhoneOutlined fontSize="small" />
                <div style={{display: 'flex', flexDirection: 'column', fontFamily: 'Roboto', paddingLeft: '1.5rem', color: '#626262'}}>
                    <span>Tel. 095449778</span>
                    <span style={{marginTop: 8, display: 'block'}}>Fax 0952246113</span>
                </div>
            </div>
            <div style={{display: 'flex', color:'#1B2B58', marginTop: '1rem'}}>
                <EmailOutlined fontSize="small" />
                <div style={{display: 'flex', flexDirection: 'column', fontFamily: 'Roboto', paddingLeft: '1.5rem', color: '#626262'}}>
                    <span>Email: direzione@eraclitea.it</span>
                    <span style={{marginTop: 8, display: 'block'}}>Pec: eraclitea@pec.it </span>
                </div>
            </div>
            <div style={{display: 'flex', color:'#1B2B58', marginTop: '1rem'}}>
                <PinDropOutlined fontSize="small" />
                <div style={{display: 'flex', flexDirection: 'column', fontFamily: 'Roboto', paddingLeft: '1.5rem', color: '#626262'}}>
                    <span>Viale della Libertà n. 106</span>
                    <span style={{marginTop: 8, display: 'block'}}>CATANIA (CT)</span>
                </div>
            </div>
            <div style={{display: 'flex', color:'#1B2B58', marginTop: '1rem'}}>
                <LanguageOutlined fontSize="small" />
                <div style={{display: 'flex', flexDirection: 'column', fontFamily: 'Roboto', paddingLeft: '1.5rem', color: '#626262'}}>
                    <span>www.sicurezzaeformazione.com</span>
                </div>
            </div>
        </div>
    </div>);
}

export default Card;