const ServerConfig = {
    AWS: {
        secret: "wTz8doI/Y1iSbyLy1+RA+izm0mehaGQeXL2iEjsu",
        accessKey: "AKIAWIA3QRRZSXV44HII",
        s3Bucket: {
            Region: 'eu-west-1',
            BucketName: 'mase-storage',
            ApiVersion: '2006-03-01',
            AccessKeyId: 'AKIAWIA3QRRZSXV44HII',
            SecretKeyId: 'wTz8doI/Y1iSbyLy1+RA+izm0mehaGQeXL2iEjsu'
        },
        Cognito: {
            Region: "eu-west-1",
            UserPoolId: "eu-west-1_5fsMoML7r",
            ClientId: "3octfff3vf5jp8a0rv0b3ohjjr",
        }
    },
    authTimeout: 18000,
    platformName: 'MASE',
}

export { ServerConfig };