import React from 'react';
import Card from '../components/Card';
import { Add, DashboardCustomize, ExpandMore, FilterList, Language, Newspaper } from '@mui/icons-material';
import CustomButton from '../components/Button';

import { Box, TextField, Typography } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import CardCertificatoDetail from '../components/CardCertificatoDetail';

const style={
    container: {
        paddingLeft: 28, 
        paddingRight: 26,
    }
}
const Certificazioni = () => {
    const [items, setItems] = React.useState([
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'},
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'},
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'}
    ])
    const [centriFormazione, setCentriFormazione] = React.useState([
        {
            icon: <Language fontSize="large" />,
            text: 'Accademia Eraclitea',
            widgets: [{title: 'Città', description: 'Ravenna'}, {title: 'Distanza', description: '0.2km'}, {title: 'Costo', description: '€300'}]
        },
        {
            icon: <Language fontSize="large" />,
            text: 'Accademia Archimedea',
            widgets: [{title: 'Città', description: 'Roma'}, {title: 'Distanza', description: '102.5km'}, {title: 'Costo', description: '€300'}]
        },
        {
            icon: <Language fontSize="large" />,
            text: 'Accademia Sanscrito',
            widgets: [{title: 'Città', description: 'Napoli'}, {title: 'Distanza', description: '302.0km'}, {title: 'Costo', description: '€300'}]
        },
    ])
    return (<div>
            <span style={{fontSize: '2rem', fontWeight: 600, color: '#3d4a71', marginBottom: 12, display: 'block'}}>Centri di Formazione</span>
        <Box elevation={3} sx={{width: '100%', height: 85, marginTop: '23px',  backgroundColor: '#fff', boxShadow: '0px -1px 6px #00000029', display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', alignItems: 'center', paddingLeft: '28px'}}>
                <Typography color="primary" textTransform={'uppercase'} fontSize="16px" alignItems="center" display="flex">Certificazioni &nbsp;<ExpandMore fontSize="16px"/></Typography>

            </div>
            <div style={{display: 'flex', alignItems: 'center', paddingRight: '28px'}}>
                <Typography color="primary" textTransform={'uppercase'} fontSize="16px" alignItems="center" display="flex">Ordina per &nbsp;<ExpandMore fontSize="16px"/></Typography>

            </div>
        </Box>
        <div style={style.container}>
            
            <div style={{paddingLeft: 24, paddingRight: 24, paddingTop: '2rem'}}>
                <Typography color="#626262" fontSize="19px">Trova centri vicino a...</Typography>
                <TextField variant="filled" fullWidth sx={{
                    border: 0,
                    backgroundColor: '#fff'
                }}></TextField>
            </div>
            <div style={{display: 'flex', width: '100%', flexDirection: 'column', marginTop: '2rem'}}>{
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto', alignItems: 'center'}}>{
                    centriFormazione.map((item) => (<>
                        <CardCertificatoDetail icon={item.icon} text={item.text} widgets={item.widgets} />
                        </>
                    ))
                }</div>
            }</div>
        </div>
        <CustomButton text="Carica altri" fontFamily="Spectral" inverse={true} style={{marginTop: 24}}/>
        
    </div>
    
    
    )
    
}

export default Certificazioni;