import React from "react";
import CustomButton from "./Button";

const OverlayedBackgroundDiv = (props) => (<div style={{marginTop: 12, width: '100%', backgroundImage: props.source ? `url(${props.source})` : 'url(/images/trova_imbarco.jpeg)', position: 'relative', backgroundSize: 'cover', backgroundPosition: 'center'}}>
    <div className="content">
        <h2 style={{paddingTop: 12, fontSize: 32}}>{props.text}</h2>

        {props.content}
        
    </div>
    <CustomButton className="absolute-btn" text="Vedi tutti" onClick={props.action} />

</div>);
export default OverlayedBackgroundDiv

