import { Typography } from '@mui/material';
import React from 'react';
import CustomButton from './Button';
import { AddCircleOutlined } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
const style = {
    card: {
        width: 290,
        minHeight: 290,
        backgroundColor: '#fff',
        color: '#1B2B58',
        boxShadow: '0px 0px 17px 0px rgba(0,0,0,0.25)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        padding: 23,
        flexShrink: 0,
        marginTop: '2rem',
        position: 'relative'
    },
    widget: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#555',
        width: '100%'
    },
    singleWidget: {
        flexDirection: 'column',
        display: 'flex'
    }
}

const Card = (props) => {
    const [popover, setPopover] = React.useState(null);

    console.log('OGGETTO CERTIFICATI', props.info);
    return (
    <div style={style.card}>
         {props.info?.length > 0 && <div style={{position: 'absolute', right: 12, top: 12}}>

<div onClick={(e) => setPopover(e.currentTarget)}>
    <InfoIcon />
</div>
<Popover
id={'info-popover'}
open={Boolean(popover)}
anchorEl={popover}
onClose={() => setPopover(null)}
anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
}}
>
    <Typography sx={{ p: 2 }}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
        {Object.keys(props.info?.reduce((acc, obj) => {
            const key = obj.title;
            if(!acc[key])acc[key] = [];
            acc[key].push(obj);
            return acc;
        }, {})).map(x => (
            <>
             <strong>{x}:</strong>
             <ul>
                {props.info?.filter(y => y.title === x).map(
                    y => <li>{y.description.includes('https://') ? (<a href={y.description}>Vedi Link</a>) : y.description}</li>
                )}
             </ul>
            </>
           
        ))}
        </div>
        
    </Typography>
</Popover>
</div>}
        <h2 style={{fontFamily: 'Spectral'}}>Corsi obbligatori</h2>
        {/* <div style={{height: 2, width: '100%', backgroundColor: '#eee', marginBottom: '0.5rem'}}></div> */}
        {/* <hr /> */}
        {props.certificates.filter(x => x["obbligatorieta'"] === 'true').map((item, idx) => (<><div key={`row-${idx}`} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', textAlign: 'left'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#1B2B58" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="bold" >{item.name}</Typography>
                    <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#c1c1c1" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="medium" >STCW Reg: {item.registry}</Typography>
                </div>
                <AddCircleOutlined onClick={() => props.onExternalLoad(item.idCourse)} />
            </div>
            
           
                
            </div>
            {props.certificates.length - 1 !== idx && <div style={{height: 2, width: '100%', backgroundColor: '#eee', marginTop: '0.7rem', marginBottom: '0.8rem'}}></div>}
            
        </>
            
        ))}
        {props.notOnlyRequired && <>
            <h2 style={{fontFamily: 'Spectral'}}>Corsi propedeutici</h2>
            {console.log(props.certificates)}
            {props.certificates.filter(x => x["obbligatorieta'"] === 'PROPEDEUTICO' || x["obbligatorieta'"] === 'PROPEDEUDICO').filter((obj, index, self) =>
                index === self.findIndex((t) => t.name === obj.name)
            ).map((item, idx) => (<><div key={`row-${idx}`} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', textAlign: 'left'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                    <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#1B2B58" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="bold" >{item.name}</Typography>
                    <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#c1c1c1" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="medium" >STCW Reg: {item.registry}</Typography>
                    </div>
                    <AddCircleOutlined onClick={() => props.onExternalLoad(item.idCourse)} />
                </div>
                    
                    
                </div>
                {props.certificates.length - 1 !== idx && <div style={{height: 2, width: '100%', backgroundColor: '#eee', marginTop: '0.7rem', marginBottom: '0.8rem'}}></div>}
                
            </>
                
            ))}
            {/* {props.certificates?.filter(x => x["obbligatorieta'"] === 'PROPEDEUTICO' || x["obbligatorieta'"] === 'PROPEDEUDICO').map((item, idx) => (<><div key={`row-${idx}`} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', textAlign: 'left'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#1B2B58" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="bold" >{item.name}</Typography>
                <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#c1c1c1" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="medium" >STCW Reg: {item.registry}</Typography>
            </div>
            <AddCircleOutlined onClick={() => props.onExternalLoad(item.idCourse)} />
            </div>
                
            </div>
            {props.certificates.length - 1 !== idx && <div style={{height: 2, width: '100%', backgroundColor: '#eee', marginTop: '0.7rem', marginBottom: '0.8rem'}}></div>}
            
        </>
            
        ))} */}
        </>}
        {props.notOnlyRequired && <>
            <h2 style={{fontFamily: 'Spectral'}}>Corsi Addizionali</h2>
            {props.certificates.filter(x => x["obbligatorieta'"] === 'ADDIZIONALE').filter((obj, index, self) =>
                index === self.findIndex((t) => t.name === obj.name)
            ).map((item, idx) => (<><div key={`row-${idx}`} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', textAlign: 'left'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                    <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#1B2B58" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="bold" >{item.name}</Typography>
                    <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#c1c1c1" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="medium" >STCW Reg: {item.registry}</Typography>
                    </div>
                    <AddCircleOutlined onClick={() => props.onExternalLoad(item.idCourse)} />
                </div>
                    
                    
                </div>
                {props.certificates.length - 1 !== idx && <div style={{height: 2, width: '100%', backgroundColor: '#eee', marginTop: '0.7rem', marginBottom: '0.8rem'}}></div>}
                
            </>
                
            ))}
            {/* {props.certificates.filter(x => x["obbligatorieta'"] === 'ADDIZIONALE').map((item, idx) => (<><div key={`row-${idx}`} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', textAlign: 'left'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#1B2B58" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="bold" >{item.name}</Typography>
                <Typography textTransform={'uppercase'} justifyContent={"center"} textAlign="left" marginTop="5px" color="#c1c1c1" fontFamily="Spectral" textAlign="left" fontSize="12px" fontWeight="medium" >STCW Reg: {item.registry}</Typography>
                </div>
                <AddCircleOutlined onClick={() => props.onExternalLoad(item.idCourse)} />
            </div>
                
                
            </div>
            {props.certificates.length - 1 !== idx && <div style={{height: 2, width: '100%', backgroundColor: '#eee', marginTop: '0.7rem', marginBottom: '0.8rem'}}></div>}
            
        </>
            
        ))} */}
        </>}

    </div>);
}

export default Card;