import React from "react";
import { Button } from "@mui/material";
const CustomButton = (props) => {
    return (
        <Button 
        className={props.className}
        style={{backgroundColor: props.inverse ? '#1B2B58' : '#fff', 
            borderRadius: 32, 
            fontFamily: props.fontFamily ? props.fontFamily : 'Roboto',
            padding: props.small ? '8px 10px' : '8px 38px',
            textTransform: 'inherit',
            fontWeight: '800', 
            color: props.inverse ? '#fff' : '#062069',
            boxShadow: '0px 0px 17px 0px rgba(0,0,0,0.25)',
            opacity: props.disabled ? '0.4' : 1,
            ...props.style
        }}
        disabled={props.disabled}
        onClick={props.onClick}
        type={props.type}
        >{props.icon}&nbsp;&nbsp;{props.text}</Button>
    )
}

export default CustomButton;