import * as React from 'react';

import Container from '@mui/material/Container';

import 'typeface-titillium-web';

import '@dej611/spid-react-button/dist/index.css'

import { useParams } from 'react-router-dom'
import ImageGallery from "react-image-gallery";
import moment from 'moment';

function isDateGreaterThanToday(date) {
    // Create a moment object for the given date
    const givenDate = moment(date);

    // Create a moment object for today
    const today = moment().startOf('day'); // Start of the day to ignore time

    // Compare the dates
    return givenDate.isAfter(today);
}

export default function School() {

  const [loading, setLoading] = React.useState(false)

  const [request, setRequest] = React.useState({
    type: "",
    service: "",
    name: "",
    email: "",
    phone: "",
    message: ""
  })
  
  const [school, setSchool] = React.useState({});

  const [courses, setCourses] = React.useState([]);

  const sendRequest = async() => {
    setLoading(true);
    try{
      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/services`, {
        method: 'POST',
        body: JSON.stringify(request)
      });
      setLoading(false);
      alert("Richiesta inviata con successo!");
    }catch(ex){
      alert('Errore server');
      console.log(ex);
      setLoading(false);
    }
    

  }

  const { id } = useParams();

  const getSchool = async() => {
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/school/${id}`);
    setSchool(await res.json());
  }

  const getCourses = async() => {
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/courses/${id}`);
    setCourses([...await res.json()])
  }
  React.useEffect(() => {
    getSchool();
    getCourses();
  }, []);
  return (
    <>
    {<div component="main" maxWidth="xs">
        {school.school && <h1>{school.school.name}</h1>}
        {school.gallery && <ImageGallery autoPlay showBullets showPlayButton={false} showFullscreenButton={false} items={school.gallery.map(x => ({
            original: x.img_url,
            thumbnail: x.img_url
        }))} />}
        {school.school && <div style={{textAlign: 'left', padding: 14, marginTop: 32}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div><strong>Indirizzo: </strong><p>{school.school.indirizzo_scuola}</p></div>
                <div><strong>Telefono: </strong><p>{school.school.telefono}</p></div>
            </div>
           
            {school.school.url !== "" && <a href={school.school.url} target="_blank" style={{textDecoration: 'none'}}>Visualizza Sito</a>}

            <p style={{marginTop: 24}}>
                {school.school.description}
            </p>
            </div>}
            <div style={{padding: 14}}>
                {courses.length > 0 && <h3>Prossimi corsi</h3>}
                {courses.length > 0 && courses.filter(x => isDateGreaterThanToday(x.from)).map(x => (
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <strong style={{marginTop: 16}}>{x['nome corso']}</strong>
                        <p>{moment(x.from).format('DD/MM/YYYY')}</p>
                    </div>
                ))}
            </div>
            
      </div>}
    </>
      
  );
}