import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthHelper } from '../helpers/AuthHelper';
import 'typeface-titillium-web';
import {SPIDReactButton} from '@dej611/spid-react-button'

import '@dej611/spid-react-button/dist/index.css'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://m-a-s-e.it/">
        m-a-s-e.it
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.


export default function Community() {

  return (
    <>
      <h1>Work in progress</h1>
      <img  style={{width: 200}} src="https://static.vecteezy.com/system/resources/previews/026/111/811/original/shipyard-manufacturing-process-line-icon-illustration-vector.jpg" />
    </>
      
  );
}