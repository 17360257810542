import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthHelper } from '../helpers/AuthHelper';
import 'typeface-titillium-web';
import {SPIDReactButton} from '@dej611/spid-react-button'

import '@dej611/spid-react-button/dist/index.css'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';


export default function Servizi() {

  const [loading, setLoading] = React.useState(false)

  const [request, setRequest] = React.useState({
    type: "",
    service: "",
    name: "",
    email: "",
    phone: "",
    message: ""
  })
  const [services, setServices] = React.useState({
    "Marittimi": [
      "Creazione del tuo CV Professionale & Cover Letter!",
      "Preparazione ad un colloquio vincente!",
      "Preparazione per Inglese Base & Avanzato;",
      "Preparazione per Esami di Patente Nautica;",
      "Preparazione per Esami di Patentino Coperta & Macchina;",
      "Preparazione per Esami di Patente Coperta & Macchina;",
      "Preparazione per Esame Marittimi Abilitati & Capo Barca;",
      "Preparazione per Concorso Pilota;",
      "Assicurazione Titolo;",
      "Tutela Medico Legale;"
    ],
    "Armatori": [
      "Perizie Imbarcazioni",
      "Trasferimento Imbarcazioni",
      "Ricerca & Selezione Equipaggio",
      "Pratiche Nautiche"
    ]
  })

  const sendRequest = async() => {
    setLoading(true);
    try{
      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/services`, {
        method: 'POST',
        body: JSON.stringify(request)
      });
      setLoading(false);
      alert("Richiesta inviata con successo!");
    }catch(ex){
      alert('Errore server');
      console.log(ex);
      setLoading(false);
    }
    

  }
  return (
    <>
    {<Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <img src="/images/New Logo MASE  TR2 mod.png" width={125} height={125} alt="logo"/>

          <Typography component="h1" variant="h5" fontWeight="bold">
            Richiedi servizi MASE
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <FormControl fullWidth sx={{mb: 3, mt: 3}}>
                <InputLabel>Tipo servizi</InputLabel>
                <Select
                label="Tipo servizi"
                fullWidth
                onChange={(e) => setRequest({...request, type: e.target.value})}
                >
                  <MenuItem></MenuItem>
                  <MenuItem value="Armatori">Armatori</MenuItem>
                  <MenuItem value="Marittimi">Marittimi</MenuItem>
                </Select>
            </FormControl>
           
            {request.type !== "" && 
            <FormControl fullWidth sx={{mb: 3, mt: 3}}>
              <InputLabel>Servizi</InputLabel>
                <Select
                  label="Servizi"
                  onChange={(e) => setRequest({...request, service: e.target.value})}
                  autoWidth
                >
                  {services[request.type].map(service => (
                    <MenuItem value={service} sx={{fontSize: 10}}>{service}</MenuItem>
                  ))}
                </Select>
            </FormControl>
            }
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nome e Cognome"
              name="name"
              onChange={(e) => setRequest({...request, name: e.target.value})}              
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              sx={{mt: 3, mb: 3}}
              onChange={(e) => setRequest({...request, email: e.target.value})}              
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Telefono"
              name="phone"
              sx={{mt: 3, mb: 3}}
              autoComplete="phone"
              onChange={(e) => setRequest({...request, phone: e.target.value})}              
            />
            <TextField
              margin="normal"
              required
              multiline
              fullWidth
              id="message"
              label="Messaggio"
              name="message"
              rows={4}
              sx={{mt: 3, mb: 3}}
              onChange={(e) => setRequest({...request, message: e.target.value})}              
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
              onClick={() => sendRequest()}
            >
              Invia richiesta
            </LoadingButton>
            
          </Box>
        </Box>
      </Container>}
    </>
      
  );
}