import React, { useState } from 'react';
import Card from '../components/Card';
import { Add, ChevronLeft, ChevronRight, DashboardCustomize, ExpandLess, ExpandMore, FilterList, Language, Newspaper } from '@mui/icons-material';
import CustomButton from '../components/Button';

import { Box, Typography } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import CardCertificati from '../components/CardCertificati';
import CardImbarchiExpanded from '../components/CardImbarchiExpanded';
import OverlayedBackgroundDiv from '../components/OverlayedBackground';
import { useNavigate } from 'react-router-dom';
import FindImbarchi from '../components/FindImbarchi';
import moment from 'moment';
import { AuthHelper } from '../helpers/AuthHelper';

const style={
    container: {
        paddingLeft: 28, 
        paddingRight: 26,
        paddingTop: '2rem'
    }
}
const mesi = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ];
  
const TuoiImbarchi = (props) => {
    const [imbarchi, setImbarchi] = React.useState([]);

    const getImbarchi = async() => {
        const user = await AuthHelper.getUserFromIdToken();
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/imbarchi?user=${user.Username}`);
        setImbarchi([...await res.json()]);
    }
    const [items, setItems] = React.useState([
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'},
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'},
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'}
    ])
    
    const [ships, setShips] = React.useState([
        {name: 'NomeNave', type: 'Tipologia 1', durance: '2m 3g', grt: '120k', imbarcato: '01/01/24', sbarcato: '04/03/24', qualifica: 'Allievo ufficiale di coperta'},
        {name: 'NomeNave', type: 'Tipologia 1', durance: '2m 3g', grt: '120k', imbarcato: '01/01/24', sbarcato: '04/03/24', qualifica: 'Allievo ufficiale di coperta'}
    ])
    const navigate = useNavigate();

    React.useEffect(() => {
        getImbarchi();
    }, [])
    const [selectExpanded, setSelectExpanded] = React.useState(-1);

    const [selectedMonth, setSelectedMonth] = React.useState(new Date().getMonth())

    const getRole = (id) => {
        const roles = JSON.parse(sessionStorage.getItem('roles'));
        return roles.filter(x => x.id === id)[0]?.name
    }
    return (<div>
            <span style={{fontSize: '2rem', fontWeight: 600, color: '#3d4a71', marginBottom: 12, display: 'block'}}>I tuoi Imbarchi</span>
                {/* <Box elevation={3} sx={{width: '100%', height: 75, marginTop: '23px',  backgroundColor: '#fff', boxShadow: '0px -1px 6px #00000029', display: 'flex', justifyContent: 'space-between', color: '#1B2B58', alignItems: 'center'}}>
                    <div style={{paddingLeft: 28}} onClick={() => setSelectedMonth(selectedMonth === 0 ? 11 : selectedMonth-1)}><ChevronLeft /></div>
                    <Typography textTransform={'uppercase'} fontSize={18}>{mesi[selectedMonth]} 2023</Typography>
                    <div style={{paddingRight: 28}} onClick={() => setSelectedMonth(selectedMonth === 11 ? 0 : selectedMonth+1)}><ChevronRight /></div>
                </Box> */}
                <div style={style.container}>
                    {imbarchi.map((item, idx) => 
                    {
                        const date1 = moment(item.onboarded);
                        const date2 = moment(item.offboarded);

                        const differenceInMonths = date2.diff(date1, 'months');

                        const daysDifference = date2.diff(date1.add(differenceInMonths, 'months'), 'days');

                        return (
                        <div onClick={() => idx === selectExpanded ? setSelectExpanded(-1) : setSelectExpanded(idx)} style={{display: 'flex', justifyContent: 'space-between', color: '#626262', alignItems: 'center', paddingBottom: '0.8rem', paddingTop: '0.8rem', borderBottom: idx !== ships.length - 1 ? '1px solid #eee' : 'none', flexWrap: 'wrap'}}>
                            <div style={{width: 75, textAlign: 'left'}}>
                                <strong style={{fontSize: 13}}>{item.name}</strong>
                            </div>
                            
                            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                <span style={{textTransform: 'uppercase', fontSize: 10}}>Durata</span>
                                <strong>{ differenceInMonths } mesi {daysDifference} gg</strong>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                {idx === selectExpanded ? <ExpandLess /> : <ExpandMore />}
                            </div>
                            {idx === selectExpanded && <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', gap: '6px', width: '100%', paddingTop: '1.5rem', flexWrap: 'wrap'}}>
                                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                    <span style={{textTransform: 'uppercase', fontSize: 10}}>Tipo Nave</span>
                                    <strong>{item.type}</strong>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                    <span style={{textTransform: 'uppercase', fontSize: 12}}>G.R.T.</span>
                                    <strong>{item.grt}</strong>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                    <span style={{textTransform: 'uppercase', fontSize: 12}}>Imbarcato</span>
                                    <strong>{moment(item.onboarded).format('DD/MM/YYYY')}</strong>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                    <span style={{textTransform: 'uppercase', fontSize: 12}}>Sbarcato</span>
                                    <strong>{moment(item.offboarded).format('DD/MM/YYYY')}</strong>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', paddingTop: '1rem'}}>
                                    <span style={{textTransform: 'uppercase', fontSize: 12}}>Qualifica</span>
                                    <strong>{getRole(item.qualification)}</strong>
                                </div>
                            </div>}
                            
                        </div>
                    )})}
                    
                </div>
                <OverlayedBackgroundDiv text={"Trova un imbarco"} action={() => navigate('/trova-un-imbarco')} content={<FindImbarchi/>} source="https://hips.hearstapps.com/hmg-prod/images/vacanze-barca-a-vela-1558536033.jpg"/>
    </div>
    
    
    )
    
}

export default TuoiImbarchi;