import React from "react";
import CustomButton from "./Button";
import moment from "moment";

const monthsDiffReducer = (accumulator, currentItem) => {
    const onboardedDate = moment(currentItem.onboarded);
    const offboardedDate = moment(currentItem.offboarded);
  
    const monthsDiff = offboardedDate.diff(onboardedDate, 'days') / 30.44;
    return accumulator + monthsDiff;
};

const daysDiffCalculator = (accumulator, currentItem) => {
    const onboardedDate = moment(currentItem.onboarded);
    const offboardedDate = moment(currentItem.offboarded);
  
    const monthsDiff = offboardedDate.diff(onboardedDate, 'days') / 30.44;
    
    // Arrotondare al numero intero più vicino
    const roundedMonths = Math.round(monthsDiff);

    // Calcolare il numero di giorni coperti dai mesi completi
    const daysCoveredByMonths = Math.round(roundedMonths * 30.44);
    console.log('Covered by months', daysCoveredByMonths);
    // Calcolare i giorni rimanenti
    const remainingDays = daysCoveredByMonths - offboardedDate.diff(onboardedDate, 'days');
    console.log('Giorni rimanenti', offboardedDate.diff(onboardedDate, 'days'));

    return accumulator + remainingDays;
}
function calculateMonthsAndDaysDifference(startDate, endDate) {
    let start = moment(startDate);
    let end = moment(endDate);

    let totalMonths = end.diff(start, 'months');
    start.add(totalMonths, 'months');
    let totalDays = end.diff(start, 'days');

    return { months: totalMonths, days: totalDays };
}

function reduceDateDifferences(dates) {
    // if (dates.length < 2) return { months: 0, days: 0 };

    return dates.reduce((acc, date, index, array) => {
        console.log('DATE', acc, date);
        // if (index === 0) return acc;

        let difference = calculateMonthsAndDaysDifference(date.onboarded, date.offboarded);
        acc.months += difference.months;
        acc.days += difference.days;

        // Normalize days to months if days >= 30 (or approximate a month)
        if (acc.days >= 30) {
            let extraMonths = Math.floor(acc.days / 30);
            acc.months += extraMonths;
            acc.days = acc.days % 30;
        }

        return acc;
    }, { months: 0, days: 0 });
}
const Imbarchi = (props) => {
    return props.imbarchi && props.imbarchi.length > 0 ? (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: 28}}>
                    <h2 style={{fontSize: '3rem', marginBottom: 0, marginTop: 0}}>{reduceDateDifferences(props.imbarchi).months}</h2>
                    <span style={{textTransform: 'uppercase', marginTop: 0, fontFamily: 'Roboto', fontSize: 12}}>Mesi di navigazione</span>
                </div>
                <div style={{width: 2, height: '100%', backgroundColor: '#eee'}}></div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: 28}}>
                    <h2 style={{fontSize: '3rem', marginBottom: 0, marginTop: 0}}>{reduceDateDifferences(props.imbarchi).days}</h2>
                    <span style={{textTransform: 'uppercase', marginTop: 0, fontFamily: 'Roboto', fontSize: 12}}>Giorni di navigazione</span>
                </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', overflowY: 'hidden', height: 200, paddingLeft: 28, paddingRight: 28, position: 'relative', zIndex: 0}}>
                {props.imbarchi.map((item) => (
                    <div style={{marginTop: 12}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #eee', paddingLeft: 12, paddingRight: 12}}>
                            <h5>{item.name}</h5>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                <span style={{textTransform: 'uppercase', fontSize: 10, fontFamily: 'Roboto'}}>
                                    partenza
                                </span>
                                <span style={{fontSize: 16}}>
                                    {moment(item.onboarded).format('DD/MM/YYYY')}
                                </span>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                <span style={{textTransform: 'uppercase', fontSize: 10, fontFamily: 'Roboto'}}>
                                    ritorno
                                </span>
                                <span style={{fontSize: 16}}>
                                    {moment(item.offboarded).format('DD/MM/YYYY')}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
                
                
            </div>
        </div>
        
    ) : (<div style={{display: 'flex', flexDirection: 'column', padding: 3, paddingBottom: 52}}>
        <p>Non hai caricato imbarchi.<br/> Qui potrai inserire i dati relativi alle tue ultime esperienze di navigazione.</p>

    </div>)
}

export default Imbarchi;