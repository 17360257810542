import dayjs from 'dayjs';
import React from 'react';

const style = {
    card: {
        width: 225,
        minHeight: 225,
        backgroundColor: '#fff',
        color: '#1B2B58',
        boxShadow: '0px 0px 17px 0px rgba(0,0,0,0.25)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        padding: 23,
        flexShrink: 0,
        marginRight: 28
    },
    widget: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#555',
        width: '100%'
    },
    singleWidget: {
        flexDirection: 'column',
        display: 'flex'
    }
}
const Card = (props) => {
    return (
    <div onClick={props.onClick} style={{...style.card,  
        border: props.ignoreDates ? null : dayjs().isAfter(dayjs(props?.item?.expiry)) || dayjs(props?.item?.expiry).diff(dayjs(), 'days') < 30 ? '2px solid #990000' : 
        dayjs(props?.item?.expiry).diff(dayjs(), 'months') < 6 ? '2px solid #ffa500' : '2px solid rgb(6, 32, 105)'
    }}>
        <div>{props.icon}</div>
        <div><h2 style={{fontSize: '24px', minHeight: 54}}>{props.text}<br/>{props.expired && <strong style={{color: '#990000', fontStyle: 'italic'}}>Scaduto</strong>}</h2></div>
        
        <div style={{height: 2, width: '100%', backgroundColor: '#eee'}}></div>
        <hr/>
        <div style={style.widget}>
            {props.widgets?.map((widget, index) => (
                <>
                {index > 0 && <div style={{height: '100%', width: 2, backgroundColor: '#eee'}}/>}

                <div style={style.singleWidget}>

                    <span style={{fontWeight: 'regular', textTransform: 'uppercase', fontFamily: "'Roboto', sans-serif", fontSize: '0.8rem'}}>
                        {widget.title}
                    </span>
                    <span style={{fontWeight: 'bold', fontSize: '1rem'}}>
                        {widget.description}
                    </span>
                </div>
                </>
                
                
            ))}
        </div>
    </div>);
}

export default Card;