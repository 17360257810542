import { CognitoUserPool, AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { ServerConfig } from '../connectors/Config';
import { CognitoIdentityServiceProvider, config } from 'aws-sdk'
import axios from 'axios';

const confirmUserAsync = (poolId, username, identityProvider) => {
    
    return new Promise((resolve, reject) => {
        identityProvider.adminConfirmSignUp({
            UserPoolId: poolId,
            Username: username
        }, (err,data) => {
            if(err)reject(err);
            else resolve(data);
        })
    })
}
const forgotPasswordAsync = (poolId, identityProvider, email) => {
        return new Promise((resolve, reject) => {
            identityProvider.listUsers({
            UserPoolId: poolId,
            Limit: 60,
            Filter: `email = "${email}"`
        }, (err, data) => {
            if(err)reject(err);
            console.log(data.Users[0]);
            const userPool = new CognitoUserPool(ServerConfig.AWS.Cognito);

            const _user = new CognitoUser({
                Pool: userPool,
                Username: data.Users[0].Username
            });

            
            _user.forgotPassword({
                onSuccess: function(result) {
                    console.log('call result: ' + result);
                },
                onFailure: function(err) {
                    alert(err);
                },
                inputVerificationCode() { // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
                    resolve(_user);
                }
            })
        })
    });
}
const confirmUserByCodeAsync = (code, user) => {
    return new Promise((resolve, reject) => {
        user.confirmRegistration(code, false, (err , data) => {
                if(err)reject(err);
                else resolve(data);
            }
        )
    });
}
const asyncAuthenticateUser = (cognitoUser, cognitoAuthenticationDetails) => {
    return new Promise((resolve, reject) => {
        cognitoUser.associateSoftwareToken({
            onFailure : (error)=>{
                console.error(error);
            },
            associateSecretCode:(code)=>{
                console.debug(code);
            }
        });
        cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
            onSuccess: result => resolve({status: 'ok', message: result}),
            onFailure: err => reject(err),
            newPasswordRequired: err => resolve({status: 'new_password', message: 'new_password_required'})
        })
    });
}

const asyncCognitoSignup = (userPool, email, password, attributeList) => {
    return new Promise((resolve, reject) => {
        userPool.signUp(email, password, attributeList, null, (err, result) => {
            if(err)reject(err);
            else resolve(result);
        })
    })
}

const asyncChangePassword = (cognitoUser, newPassword) => {
    return new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(
            newPassword,
            null,
            {
                onSuccess: () => resolve({status: 'ok', message: 'Password cambiata con successo'}),
                onFailure: (err) => reject({status: 'error', message: err})
            }
        )
    });
}

const listUserAsync = (poolId, identityProvider, paginationToken) => {
    console.log('PAGINATION TOKEN', paginationToken);
    return new Promise((resolve, reject) => {
        identityProvider.listUsers({
            UserPoolId: poolId,
            Limit: 60,
            PaginationToken: paginationToken
        }, (err, data) => {
            if(err)reject(err);
            else resolve(data);
        })
    })
}

class AuthHelper {
    constructor(avoidPrivate = false){
        config.update({
            region: ServerConfig.AWS.Cognito.Region,
            accessKeyId: ServerConfig.AWS.accessKey,
            secretAccessKey: ServerConfig.AWS.secret
        });

        if(avoidPrivate){
            this.userPool = new CognitoUserPool(ServerConfig.AWS.Cognito);
            this.oldPassword = "";
        }else{
            throw new Error("This class is a Singleton. Retrieve it through the getIstance static method.");
        }
    }

    static confirmUser = async(user, code) => {
        try{
            const res = await confirmUserByCodeAsync(code, user);
            return res;
        }catch(ex){
            console.log(ex);
            return ex;
        }
    }
    
    static disableUser = async(username) => {
        config.update({
            region: ServerConfig.AWS.Cognito.Region,
            accessKeyId: ServerConfig.AWS.accessKey,
            secretAccessKey: ServerConfig.AWS.secret
        });

        const identityProvider = new CognitoIdentityServiceProvider();
        const res = await identityProvider.adminDisableUser({
            Username: username,
            UserPoolId: ServerConfig.AWS.Cognito.UserPoolId
        }).promise();
        if(res.$response.error?.message)return {error: res.$response.error?.message}
        else return {message: 'Utente disabilitato con successo'}
    }

    static resetPassword = async(username, password) => {
        config.update({
            region: ServerConfig.AWS.Cognito.Region,
            accessKeyId: ServerConfig.AWS.accessKey,
            secretAccessKey: ServerConfig.AWS.secret
        });

        const identityProvider = new CognitoIdentityServiceProvider();
        const res = await identityProvider.adminSetUserPassword({
            Username: username,
            UserPoolId: ServerConfig.AWS.Cognito.UserPoolId,
            Password: password,
            Permanent: false
        }).promise();
        if(res.$response.error?.message)return {error: res.$response.error?.message}
        else return {message: 'Password resettata con successo'}

    }
    static signUp = async(email, password, firstName, lastName, schoolName, website, città, address) => {
        config.update({
            region: ServerConfig.AWS.Cognito.Region,
            accessKeyId: ServerConfig.AWS.accessKey,
            secretAccessKey: ServerConfig.AWS.secret
        });
        const userPool = new CognitoUserPool(ServerConfig.AWS.Cognito);
        const nameAttribute = new CognitoUserAttribute({
            Name: 'name',
            Value: firstName
        });
        const firstNameAttribute = new CognitoUserAttribute({
            Name: 'given_name',
            Value: firstName
        });
        const lastNameAttribute = new CognitoUserAttribute({
            Name: 'family_name',
            Value: lastName
        })

        const schoolNameAttribute = new CognitoUserAttribute({
            Name: 'custom:schoolName',
            Value: schoolName
        });

        const websiteAttribute = new CognitoUserAttribute({
            Name: 'custom:website',
            Value: website
        })
        
        const cittàAttribute = new CognitoUserAttribute({
            Name: 'custom:città',
            Value: città
        })

        const addressAttribute = new CognitoUserAttribute({
            Name: 'custom:address',
            Value: address
        })
        const schoolAttribute = new CognitoUserAttribute({
            Name: 'custom:is_school',
            Value: 'true'
        })
        
        try{
            const res = await asyncCognitoSignup(userPool, email, password, [
                nameAttribute,
                firstNameAttribute,
                lastNameAttribute,
                schoolNameAttribute,
                websiteAttribute,
                cittàAttribute,
                addressAttribute,
                schoolAttribute,
            ]);

            return res;
        }catch(ex){
            console.log(ex.message);
            if(ex.message === 'An account with the given email already exists.'){
                throw new Error('Un utente con la stessa email già esiste.')
            }
            throw new Error('Errore server');
        }
        
    }
    static getUserFromIdToken = async () => {
        try{
            if(localStorage.getItem('user_bak')){
                window.location.href="/signup";
                return;
            }
            const idToken = localStorage.getItem('cruddy-apiKey');
            const url = `https://cognito-idp.${ServerConfig.AWS.Cognito.Region}.amazonaws.com/`
            const res = await axios.post(url, {
                AccessToken: idToken
            }, {
                headers: {
                    "X-Amz-Target": "AWSCognitoIdentityProviderService.GetUser",
                    "Content-Type": "application/x-amz-json-1.1",
                }
            });
             return res.data;
        }catch(ex){
            if(localStorage.getItem('user_bak')){
                window.location.href="/signup";
                return;
            }
            if(ex.response.status === 400){
                
                localStorage.clear();
                //window.location.href=`http://cruddy-sso.s3-website-eu-west-1.amazonaws.com/?invalidate=true&return-url=${window.location.href}`
                
            }
        }
        
    }
    static getInstance = () => {
        if(!this.istance){
            this.istance = new AuthHelper(true);
        }
        return this.istance;
    }
    
    static getUserByEmail = async(email, platform) => {
        const users = await AuthHelper.getUsers();
        for(let user of users){
            if(user.Attributes.filter(x => x.Name === 'email')[0].Value === email){
              
                return user;
            }
        }
        return false;
    }
    
    static forgotPassword = async(email) => {
        config.update({
            region: ServerConfig.AWS.Cognito.Region,
            accessKeyId: ServerConfig.AWS.accessKey,
            secretAccessKey: ServerConfig.AWS.secret
        });
        const res = await forgotPasswordAsync(ServerConfig.AWS.Cognito.UserPoolId, new CognitoIdentityServiceProvider(), email);
        console.log('PASSWORD DIMENTICATA RES', res);
        return res;
    }

    static confirmPassword = (code, newPassword, user) => {
        return new Promise((res, rej) => {
            user.confirmPassword(code, newPassword, {
                onSuccess: (params) => {
                    res(true);
                },
                onFailure: (params) => {
                    rej('Codice errato');
                }
            })
        })
        
    }
    static getUsers = async() => {
        try{
            let paginationToken;
            let userRemain = true;
            let users = [];
            while(userRemain){
                const res = await listUserAsync(ServerConfig.AWS.Cognito.UserPoolId, new CognitoIdentityServiceProvider(), paginationToken);
                users = [...users, ...res.Users];
                if(res.PaginationToken){
                    paginationToken = res.PaginationToken
                }else{
                    userRemain = false;
                }
                

            }
            return users;
        }catch(ex){
            console.log(ex);
        }
    }

    getRoles = async () => {
        const user = await AuthHelper.getUserFromIdToken();
        return user.UserAttributes.filter(x => x.Name === 'custom:roles').Value;
    }
    login = async(credentials) => {
        try{
            const authenticationDetails = new AuthenticationDetails(credentials);
            const userData = {
                Username: credentials.Username,
                Pool: this.userPool
            }
            const cognitoUser = new CognitoUser(userData);
            const response = await asyncAuthenticateUser(cognitoUser, authenticationDetails);
            if(response.message.accessToken){
                localStorage.setItem('cruddy-apiKey', response.message.accessToken.jwtToken);
                localStorage.setItem('cruddy-user', JSON.stringify(response.message));
            }
            this.user = cognitoUser;
            return response;
        }catch(ex){
            console.log({status: 'error', response: ex});
            return {status: 'error', response: ex};
        }
    }

    logout = () => {
        localStorage.clear();
        window.location.href = `http://mase-sso.s3-website-eu-west-1.amazonaws.com/?return-url=http://mase-admin.s3-website-eu-west-1.amazonaws.com/`
        return true;
    }
    changePassword = async(newPassword) => {
        try{
            const res = await asyncChangePassword(this.user, this.oldPassword, newPassword);
            return {status: 'success', response: res};
        }catch(ex){
            return {status: 'error', response: ex};
        }
    }
}

export { AuthHelper };