import React, { useEffect } from "react";
import CustomButton from "./Button";
import CardImbarchi from "./CardImbarchi";
import { AuthHelper } from "../helpers/AuthHelper";
import moment from "moment";




const FindImbarchi = () => {
    const [onboardings, setOnboardings] = React.useState([]);

    const getOnboardings = async() => {
        try{
            const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
            const role = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/role/${_user.filter(x => x.Name === 'sub')[0]?.Value}`);
            
            const id = (await role.json()).roleId
            const _onboardings = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/proposals/${id}`);
            setOnboardings([...await _onboardings.json()]);
        }catch(ex){
            console.error(ex);
        }
        
        
    }
    useEffect(() => {
        window.setTimeout(() => {
            getOnboardings();
        }, 500)
    }, [])

    const getRole = (id) => {
        const roles = JSON.parse(sessionStorage.getItem('roles'));
        return roles.filter(x => x.id === id)[0]?.name
    }
    const [items, setItems] = React.useState([
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'},
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'},
        {name: 'Scarani', title: 'Proposta 1', shipmentStart: '01/09/24', shipmentEnd: '17/10/24', location: 'Ravenna', role: 'Ufficiale di Coperta'}
    ])
    return (
        <div>
            <div>
                <div className="carousel" style={{display: 'flex', width: '100vw', overflow: 'auto'}}>{
                    onboardings.length > 0 ? onboardings.map((item, idx) => (<>
                        <CardImbarchi name={item.shipper} title={`Proposta ${idx + 1}`} shipmentStart={moment(item.startDate).format('DD/MM/YYYY')} shipmentEnd={moment(item.endDate).format('DD/MM/YYYY')} location={item.location} role={getRole(item.role)} />
                    </>
                    )) : <div style={{width: '100%', height: 150, display: 'flex', justifyContent: 'center', color: '#fff'}}>
                        Non è stato trovato nessun imbarco per il tuo ruolo
                    </div>
                }</div>
            </div>
            {/* <CustomButton text="Scopri di più" /> */}
        </div>
    )
}

export default FindImbarchi;