import React, { useRef } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { AuthHelper } from '../helpers/AuthHelper';
import { Alert, Modal, TextField, Box, Typography, ToggleButton, ToggleButtonGroup, Stack, Select, MenuItem, Button } from '@mui/material';
import { ServerConfig } from '../connectors/Config';
import { config, S3 } from 'aws-sdk';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import { Buffer } from "buffer";
import Badge from '@mui/material/Badge';
import { Notifications } from '@mui/icons-material';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const style = {
    headerContainer: {
        height: 100,
        backgroundColor: '#062069',
        display: 'flex',
        alignItems: 'center',
        position: 'fixed',
        width: '100vw',
        zIndex: 999
    },
    logo: {
        width: 75,
        height: 75,
        padding: 0,
        borderRadius: '100%',
        backgroundColor: '#fff',
        marginLeft: 26
    },
    person: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'flex-start',
        color: '#fff',
        paddingLeft: '24px',
        width: '100%',
        paddingRight: '24px'
    }
}
const Header = () => {
    const [user, setUser] = React.useState([]);
    const [role, setRole] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [category, setCategory] = React.useState(null);
    const [roles, setRoles] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState("");
    const [profile, setProfile] = React.useState({});
    const [imo, setIMO] = React.useState("");
    const [matricola, setMatricola] = React.useState("");
    const [compartimento_marittimo, setCompartimento_marittimo] = React.useState("");

    const [countNotifications, setCountNotifications] = React.useState(0);

    const getNotifications = async() => {
        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/notifications/count/${_user.filter(x => x.Name === 'sub')[0]?.Value}`)
        setCountNotifications(await res.json());
    }
    const getUser = async () => {
        
        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
        if(!_user)window.location.href="/login"
        sessionStorage.setItem('user', JSON.stringify(user));
        console.log(_user);
        setUser([..._user])

        const getRole = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/role/${_user.filter(x => x.Name === 'sub')[0]?.Value}`)
        setRole((await getRole.json()).role);

        const getProfile = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/profile/${_user.filter(x => x.Name === 'sub')[0]?.Value}`);
        setProfile({...await getProfile.json()})
    }

    
    React.useEffect(() => {
        getUser();
        window.setInterval(() => {
            getNotifications();
        }, 10000);
        getNotifications();
    }, [])

    const getRolesByCategory = async() => {
        const getRoles = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/roles/${category}`)
        setRoles([...await getRoles.json()])
    }
    React.useEffect(() => {
        if(category) getRolesByCategory();
        
    }, [category]);

    const [loading, setLoading] = React.useState(false);
    const fileConverterBase64 = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                res(reader.result)
            }
            reader.onerror = (err) => {
                rej(err)
            }
        })
    }
    const hiddenFileInput = useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current?.click();
    };
    const handleChange = async (e) => {
        setLoading(true);

        config.update({
            Region: ServerConfig.AWS.s3Bucket.Region, 
            credentials: {
                accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
                secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
            }});
        const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, credentials: {
            accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
            secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
        }});
        try{
            const fileBase64 = await fileConverterBase64(e.target.files[0]);
            const fileParticles = fileBase64.split(',')[0];
            const fileName = `${moment().valueOf()}.imo.${fileParticles.split(';')[0].split('/')[1]}`
            const params = {
                Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                Key: fileName,
                Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                ContentType: e.target.files[0].type,
                ACL: 'public-read'
            };
            
            const res = await s3.putObject(params).promise();
            setIMO(`https://mase-storage.s3.eu-west-1.amazonaws.com/${fileName}`);
            setLoading(false);
        }catch(ex){
            console.log(ex);
            alert('Errore server')
        }
    };
    const loadCertificate = async() => {
        if(!imo && roles.filter(x => x.id === selectedRole)[0]?.imo){
            alert("MANCA IL CERTIFICATO IMO");
            return;
        }
        const user = await AuthHelper.getUserFromIdToken();
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/initiate/${user.Username}`, {
            method: 'PUT',
            body: JSON.stringify({role: selectedRole, IMO: imo, compartimento_marittimo, matricola})
        })
        setLoading(false);
        window.location.reload();
        console.log(res);
    }
    return (<><header style={style.headerContainer}>
        <div style={style.logo} onClick={() => window.location.href="/"}>
            <img style={{objectFit: 'cover', borderRadius: 660}} src={profile?.avatar ? profile?.avatar :"/images/New Logo MASE  TR2 mod.png"} width={75} height={75} alt="logo"/>
        </div>
        <div style={style.person}>
            <h2 style={{margin: 0, fontSize: '1.2rem'}}>{user.length > 0 && `${user.filter(x => x.Name === 'name')[0].Value} ${user.filter(x => x.Name === 'family_name')[0].Value}`}</h2>
            <h5 style={{fontFamily: "'Roboto', sans-serif", margin: 0, fontWeight: '300', textTransform: 'uppercase', fontSize: '.750rem', textAlign: 'left'}}>{role}</h5>

        </div>
        <Badge badgeContent={countNotifications} color="secondary" sx={{marginRight: 6}}>
            <Notifications htmlColor="#fff" onClick={() => window.location.href="/notifications"}/>
        </Badge>
    </header>
    {role === "" || !role && <Alert id="alert-role" severity="error" onClick={() => setOpen(true)} sx={{
        textAlign: 'left',
        position: 'sticky',
        top: 100,
        zIndex: 999,
    }}>Non hai selezionato il tuo ruolo. Clicca qui per farlo ora.</Alert>}
    <Modal
        open={open}
        sx={{width: '100vw'}}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
            <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
            />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Seleziona ora il tuo grado
          </Typography>
          <Stack>
            <Typography variant="caption" sx={{ mt: 2 }}>
                Seleziona categoria
            </Typography>
            <ToggleButtonGroup
                value={category}
                exclusive
                onChange={(e, nValue) => setCategory(nValue)}
                aria-label="text alignment"
                >
                <ToggleButton value="1" aria-label="left aligned">
                    1^
                </ToggleButton>
                <ToggleButton value="2" aria-label="centered">
                    2^
                </ToggleButton>
                <ToggleButton value="3" aria-label="right aligned">
                    3^
                </ToggleButton>
                {/**645dca28-6433-46bb-9767-6c86bf71dbba */}
            </ToggleButtonGroup>
            <Typography variant="caption" sx={{ mt: 2 }}>
                Seleziona ruolo
            </Typography>
            <Select onChange={(e, v) => setSelectedRole(e.target.value)} disabled={roles.length === 0}>
                {roles.sort((a, b) => a.name.localeCompare(b.name)).map((x) => (
                    <MenuItem value={x.id}>{x.name}</MenuItem>
                ))}
            </Select>
            
            <TextField
                            margin="normal"
                            fullWidth
                            id="id"
                            label="Matricola"
                            placeholder="Matricola"
                            name="id"
                            onChange={(e) => setMatricola(e.target.value)}
                        />
            <TextField
                            margin="normal"
                            fullWidth
                            id="id"
                            label="Compartimento marittimo"
                            placeholder="Compartimento marittimo"
                            name="id"
                            onChange={(e) => setCompartimento_marittimo(e.target.value)}
                        />
            {console.log(roles.filter(x => x.id === selectedRole))}
            {!roles.filter(x => x.id === selectedRole)[0]?.name?.includes('Allievo') && roles.filter(x => x.id === selectedRole)[0]?.imo && <Button onClick={handleClick} variant="contained" sx={{mt: 2}} color="primary">Carica il certificato IMO</Button>}
            <LoadingButton variant="contained" color="primary" onClick={() => loadCertificate()} sx={{mt: 2}} loading={loading}>Salva</LoadingButton>

          </Stack>
          
        </Box>
      </Modal>
    </>)
}

export default Header;